import React, { useState } from "react"
import { Formik, FormikProps, Form } from "formik"
import * as yup from "yup"
import Input from "../form/input"
import { Button } from "../button"
import StatusCard, { StatusCardProps } from "./status-card"
import * as classes from "./home-valuation.module.css"
import { saveRefinanceLead, convertErrorToFormikErrors } from "../../utils/api"

interface HomeValuationFormProps {
  name: string
  email: string
  property: string
}

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  property: yup.string().required("Required"),
})

const HomeValuation: React.FunctionComponent = () => {
  const [status, setStatus] = useState<StatusCardProps | null>(null)

  return (
    <div className={classes.homeValuation}>
      <h2>Free home valuation report</h2>
      <p>
        Understand your home’s true value, discover neighborhood data and market
        trends,
        <br />
        and find out if your property qualifies for an appraisal waiver.
      </p>
      <section className={classes.homeValuationForm__row}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            property: "",
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            const [firstName] = values.name.split(" ")

            saveRefinanceLead(values).then(resp => {
              if (resp.success === false) {
                if (resp.details) {
                  actions.setSubmitting(false)
                  actions.setErrors(convertErrorToFormikErrors(resp))
                } else {
                  setStatus({
                    title: `We’re almost there!`,
                    message: `We currently operate only in California but we’re expanding soon.`,
                    icon: "no-location",
                  })
                }
              } else {
                setStatus({
                  title: `Thanks, ${firstName}!`,
                  message: `Your report is on its way. A dedicated loan officer will be in touch with you shortly.`,
                  icon: "success",
                })
              }
            })
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
          }: FormikProps<HomeValuationFormProps>) => (
            <Form>
              <section className={classes.homeValuationForm__contents}>
                <Input
                  type="text"
                  name="name"
                  placeholder="Full name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  errorMessage={touched.name && errors.name}
                />
                <Input
                  type="text"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessage={touched.email && errors.email}
                />
                <Input
                  type="text"
                  name="property"
                  placeholder="Property address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.property}
                  errorMessage={touched.property && errors.property}
                />
              </section>
              <Button type="submit" disabled={isSubmitting}>
                Get my report
              </Button>
            </Form>
          )}
        />
        <section className={classes.statusContainer}>
          {status !== null && (
            <StatusCard
              title={status.title}
              message={status.message}
              icon={status.icon}
            />
          )}
        </section>
      </section>
    </div>
  )
}

export default HomeValuation
