import React from "react"
import { AppStoreButton } from "../button"
import HeroContainer, {
  HeroContent,
  HeroContainerProps,
} from "../containers/hero"
import * as classes from "./get-started.module.css"

const stats = [
  {
    value: "70+ lenders",
    desc: "in our network",
  },
  {
    value: "1000s",
    desc: "of loan options",
  },
  {
    value: "500",
    desc: "pre-approvals YTD",
  },
  {
    value: "$100m",
    desc: "loans originated YTD",
  },
]

interface GetStartedProps {
  className?: string
  heroApp?: boolean
}

const GetStarted: React.FunctionComponent<
  GetStartedProps & HeroContainerProps
> = ({ heroApp = false, ...props }) => (
  <HeroContainer {...props}>
    <HeroContent>
      <h2>{heroApp ? "Be the hero" : "Get started"}</h2>
      <h4>Join the RedDoor community.</h4>
      <section className={classes.footerStatsContainer}>
        {stats.map((stat, i) => (
          <div key={i}>
            <h4>{stat.value}</h4>
            <h6>{stat.desc}</h6>
          </div>
        ))}
      </section>
      <AppStoreButton
        text={heroApp ? "Download the Hero app" : "Download the app"}
        isHero
        heroApp={heroApp}
      />
    </HeroContent>
  </HeroContainer>
)

export default GetStarted
