import React, { useState, useEffect } from "react"
import useFetch from "react-fetch-hook"
import { animated, useSpring, config } from "react-spring"
import SEO from "../components/seo"
import { AppStoreButton } from "../components/button"
import RateFinder from "../components/refinance/rate-finder"
import HomeValuation from "../components/refinance/home-valuation"
import GetStarted from "../components/hero/get-started"
import HeroContainer, {
  HeroBackground,
  HeroContent,
} from "../components/containers/hero"
import { formatRate, formatCurrency, formatDate } from "../utils/format"
import { Rate, mockRate } from "../utils/api"
import siteConfig from "../utils/config"
import * as classes from "../components/refinance/refinance.module.css"

const RefinancePage = () => {
  const [showRate, setShowRate] = useState(false)

  const { data } = useFetch<Rate>(`${siteConfig.apiUrl}/loan-finance/rate`)
  const rate = data || mockRate

  const [ratePercent, setRatePercent] = useSpring(() => ({
    value: 0.05,
    from: { value: 0.05 },
  }))

  const lowestRateTitleProps = useSpring({
    from: {
      color: "rgba(255, 255, 255, 1)",
      top: "50%",
      transform: "translate(-50%, -50%) scale(1)",
    },
    to: {
      color: showRate ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 1)",
      top: showRate ? "0%" : "50%",
      transform: showRate
        ? "translate(-50%, -50%) scale(0.5)"
        : "translate(-50%, -50%) scale(1)",
    },
    delay: 700,
  })

  const slideDownProps = {
    from: {
      transform: "translateY(-10%)",
      opacity: 0,
    },
    to: {
      transform: showRate ? "translateY(0)" : "translateY(-10%)",
      opacity: showRate ? 1 : 0,
    },
    config: config.slow,
    delay: 1700,
  }

  const rateTitleProps = useSpring({ ...slideDownProps })
  const descriptionProps = useSpring({ ...slideDownProps, delay: 1900 })

  useEffect(() => {
    if (!data) {
      return
    }

    setShowRate(true)
    setRatePercent({
      value: rate.rate,
      delay: 1800,
      config: {
        ...config.gentle,
        precision: 0.0001,
      },
    })
  }, [data])

  return (
    <>
      <SEO title="Refinance" />
      <HeroContainer
        variant="dark"
        backgroundGradient={HeroBackground.Purple}
        className={classes.heroContainer}
      >
        <HeroContent className={classes.heroContent}>
          <animated.div className={classes.heroRate}>
            <animated.h3
              className={classes.heroRate__prelude}
              style={lowestRateTitleProps}
            >
              Today's lowest refinance rate
            </animated.h3>
            <animated.h1
              style={rateTitleProps}
              className={classes.heroRate__rate}
            >
              <animated.span>
                {ratePercent.value.to(rate => formatRate(rate))}
              </animated.span>
            </animated.h1>
            <animated.div
              style={descriptionProps}
              className={classes.heroRate__desc}
            >
              <h3>
                Discover your options, find a rate, and lock it at no points
              </h3>
              <h5>
                {formatRate(rate.apr)} APR • {formatDate(rate.lastUpdatedTime)}{" "}
                • {rate.loanTerm} {rate.name} •{" "}
                {formatCurrency(rate.options.loanAmount)} Loan Amount
              </h5>
              <AppStoreButton isHero text="Download the app" />
            </animated.div>
          </animated.div>
        </HeroContent>
      </HeroContainer>
      <section className={classes.rateFinderSection}>
        <div className="container">
          <div className={classes.rateFinderContainer}>
            <RateFinder dailyRate={rate} />
            <HomeValuation />
          </div>
        </div>
      </section>
      <GetStarted className={classes.getStartedSection} autoSize />
    </>
  )
}

export default RefinancePage
