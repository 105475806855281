import { FormikErrors } from "formik"
import config from "./config"

interface SuccessResponse {
  success: true
  message?: string
}

interface ErrorResponse {
  success: false
  error: string
  details?: unknown
}

export interface Rate {
  apr: number
  date: string
  lastUpdatedTime: string
  loanTerm: string
  name: string
  options: {
    fico: number
    loanAmount: number
    loanPurpose: string
    loanTerm: string
    ltv: number
    zipCode: string
  }
  period: number
  programName: string
  rate: number
  termYears: number
}

export interface RateRequestProps {
  ltv: number
  loanAmount: number
  zipCode: string
  fico: string
}

export interface RefinanceLeadProps {
  name: string
  email: string
  property: string
}

export const requestFinancingRate = async (
  options: RateRequestProps
): Promise<Rate | ErrorResponse> => {
  try {
    const requestOptions = {
      ...options,
      loanPurpose: "refi",
      name: "Conventional",
      loanTerm: "30 Year Fixed",
    }
    const resp = await fetch(`${config.apiUrl}/loans/web`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(requestOptions),
    })
    const results = await resp.json()

    if (Array.isArray(results) && results.length > 0) {
      const [lowestRate] = results
      return {
        ...lowestRate,
        options: requestOptions,
      }
    }

    return {
      error:
        "error" in results
          ? results.error
          : "Unfortunately we were not able to find a rate for you.",
    } as ErrorResponse
  } catch (err) {
    return {
      error: err.message,
    } as ErrorResponse
  }
}

export const saveRefinanceLead = async (
  options: RefinanceLeadProps
): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const resp = await fetch(`${config.apiUrl}/loan-finance/lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(options),
    })
    const json: SuccessResponse = await resp.json()
    return json
  } catch (err) {
    return err
  }
}

export const convertErrorToFormikErrors = <T>(
  error: ErrorResponse
): FormikErrors<T> => {
  if (Array.isArray(error.details)) {
    return error.details
      .map(detail => ({
        key: detail.context.key,
        message: detail.message,
      }))
      .reduce((dict, curr) => {
        return {
          ...dict,
          [curr.key]: curr.message,
        }
      }, {})
  }
  return {}
}

export const mockRate: Rate = {
  name: "FHA",
  date: "2019-09-25",
  apr: 0.03628,
  termYears: 30,
  programName: "FHA",
  options: {
    ltv: 0.61538461538,
    loanPurpose: "refi",
    zipCode: "95816",
    loanTerm: "30 Year Fixed",
    loanAmount: 400000,
    fico: 800,
  },
  lastUpdatedTime: new Date().toISOString(),
  rate: 0,
  loanTerm: "30 Year Fixed",
  period: 360,
}
