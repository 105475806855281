import React from "react"
import { useSpring, config, animated } from "react-spring"

import classes from "./status-card.module.css"
import CheckCircle from "../../images/icons/check-circle.inline.svg"
import NoLocation from "../../images/icons/no-location.inline.svg"

export interface StatusCardProps {
  title: string
  message: string
  icon: "no-location" | "success"
}

const getIcon = (iconName: string) => {
  switch (iconName) {
    case "no-location":
      return <NoLocation />
    case "success":
      return <CheckCircle />
    default:
      return null
  }
}

const StatusCard: React.FunctionComponent<StatusCardProps> = ({
  title,
  message,
  icon,
}) => {
  const props = useSpring({
    from: {
      opacity: 0.8,
      transform: "scale(0.8)",
    },
    to: {
      opacity: 1,
      transform: "scale(1)",
    },
    config: config.wobbly,
  })
  return (
    <>
      <animated.section className={classes.statusCard} style={props}>
        {getIcon(icon)}
        <h4>{title}</h4>
        <p>{message}</p>
      </animated.section>
    </>
  )
}

export default StatusCard
