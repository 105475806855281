import React, { useState } from "react"
import cx from "classnames"
import Cleave from "cleave.js/react"
import * as classes from "./input.module.css"

const options = {
  currency: {
    numeral: true,
    numeralThousandsGroupStyle: "thousand",
    numeralDecimalScale: 2,
    numeralPositiveOnly: true,
    rawValueTrimPrefix: true,
    noImmediatePrefix: true,
    stripLeadingZeroes: false,
    prefix: "$",
  },
  numeric: {
    numeral: true,
  },
  zipCode: {
    blocks: [5],
    numericOnly: true,
  },
  fico: {
    blocks: [3],
    numericOnly: true,
  },
}

interface InputProps {
  format?: "currency" | "numeric" | "zipCode" | "fico"
  errorMessage?: string | boolean
}

const Input: React.FunctionComponent<
  InputProps & React.HTMLProps<HTMLInputElement>
> = ({
  placeholder,
  value,
  onChange = () => {},
  format,
  errorMessage,
  ...props
}) => {
  const [text, setText] = useState(value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
    onChange(e)
  }

  const InputElement = format ? Cleave : "input"

  return (
    <label
      className={cx(
        classes.inputWrapper,
        errorMessage && classes.inputWrapperError
      )}
    >
      <InputElement
        {...props}
        value={value}
        onChange={handleChange}
        options={format ? options[format] : {}}
        className={text && text !== "" ? classes.inputFocused : ""}
      />
      <span>{placeholder}</span>
      {typeof errorMessage === "string" && (
        <p className={classes.inputError}>{errorMessage}</p>
      )}
    </label>
  )
}

export default Input
