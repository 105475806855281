import React, { useState } from "react"
import cx from "classnames"
import * as yup from "yup"
import Input from "../form/input"
import { Button } from "../button"
import { Formik, FormikProps, Form } from "formik"
import { formatRate, formatDate, unformatCurrency } from "../../utils/format"
import { Rate, requestFinancingRate } from "../../utils/api"

import RateIcon from "../../images/icons/rate.inline.svg"
import spinnerUrl from "../../images/white-spinner.svg"
import * as classes from "./rate-finder.module.css"
import StatusCard, { StatusCardProps } from "./status-card"

interface RateFinderProps {
  dailyRate?: Rate
}

interface RateFinderFormProps {
  homeValue: string
  loanAmount: string
  zipCode: string
  fico: string
}

interface RateCardProps {
  rate?: Rate
  isLoading?: boolean
}

const schema = yup.object().shape({
  homeValue: yup.string().required("Required"),
  loanAmount: yup.string().required("Required"),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "Invalid ZIP")
    .required("Required"),
  fico: yup
    .string()
    .matches(/^\d{3}$/, "Invalid FICO")
    .required("Required"),
})

const RateCard: React.FunctionComponent<RateCardProps> = ({
  isLoading,
  rate,
}) => {
  if (!rate && !isLoading) {
    return null
  }

  if (isLoading || !rate) {
    return (
      <section
        className={cx(classes.rateFinderCard, classes.rateFinderCardLoading)}
      >
        <div>
          <img src={spinnerUrl} width={36} />
          <p>Finding your best refinance rate</p>
        </div>
      </section>
    )
  }

  return (
    <section className={classes.rateFinderCard}>
      <RateIcon />
      <div>
        <h2>{formatRate(rate.rate)}</h2>
        <h3>{formatRate(rate.apr)} APR</h3>
      </div>
      <p>
        {rate.date && (
          <>
            {formatDate(rate.lastUpdatedTime)}
            <br />
          </>
        )}
        {rate.loanTerm} {rate.name}
      </p>
    </section>
  )
}

const RateFinder: React.FunctionComponent<RateFinderProps> = ({
  dailyRate,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [userRate, setUserRate] = useState<Rate | null>(null)
  const [status, setStatus] = useState<StatusCardProps | null>(null)

  return (
    <div className={classes.rateFinder}>
      <h2 className={classes.mobileHeading}>Discover your rate</h2>
      <div className={classes.rateFinderInner}>
        <section className={classes.rateFinderForm}>
          <h2>Discover your rate</h2>
          <Formik
            validationSchema={schema}
            initialValues={{
              homeValue: "",
              loanAmount: "",
              zipCode: "",
              fico: "",
            }}
            onSubmit={(values, actions) => {
              setStatus(null)

              const loanAmount = unformatCurrency(values.loanAmount)
              const homeValue = unformatCurrency(values.homeValue)
              const ltv = loanAmount / homeValue

              if (ltv > 0.9) {
                actions.setErrors({
                  loanAmount: "Loan amount is too high",
                })
                actions.setSubmitting(false)
                return
              }

              setLoading(true)

              requestFinancingRate({
                loanAmount,
                ltv: loanAmount / homeValue,
                fico: values.fico,
                zipCode: values.zipCode,
              }).then(resp => {
                setLoading(false)
                actions.setSubmitting(false)

                if (resp && "error" in resp) {
                  setStatus({
                    title: "Oops!",
                    message: resp.error,
                    icon: "no-location",
                  })
                } else {
                  setUserRate(resp)
                }
              })
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              isSubmitting,
            }: FormikProps<RateFinderFormProps>) => (
              <Form>
                <section className={classes.rateFinderForm__contents}>
                  <Input
                    type="text"
                    name="homeValue"
                    placeholder="Home value"
                    format="currency"
                    value={values.homeValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.homeValue && errors.homeValue}
                  />
                  <Input
                    type="text"
                    name="loanAmount"
                    placeholder="Loan amount"
                    format="currency"
                    value={values.loanAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.loanAmount && errors.loanAmount}
                  />
                  <Input
                    type="text"
                    name="zipCode"
                    placeholder="ZIP code"
                    format="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.zipCode && errors.zipCode}
                  />
                  <Input
                    type="text"
                    name="fico"
                    placeholder="FICO score"
                    format="fico"
                    value={values.fico}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.fico && errors.fico}
                  />
                </section>
                <Button type="submit" disabled={isSubmitting}>
                  Find my rate
                </Button>
              </Form>
            )}
          />
        </section>
        <section className={classes.statusContainer}>
          {status !== null ? (
            <StatusCard {...status} />
          ) : (
            <RateCard
              isLoading={!dailyRate || isLoading}
              rate={userRate !== null ? userRate : dailyRate}
            />
          )}
        </section>
      </div>
    </div>
  )
}

export default RateFinder
